import { Component } from '@angular/core';

export interface Template {
  template: string;
  description: string;
}

const ELEMENT_DATA: Template[] = [
  {template: 'Standard', description: 'Create a change request for traffic request'},
  {template: '110: Multi-Approval Request', description: 'Create a traffic change request which requires multiple approvals'},
  {template: '110: Multi-Approval Request Copy', description: 'Create a traffic change request which requires multiple approvals'},
  {template: '115: Automatic Traffic Change Request', description: 'Create a traffic change request that progresses automatically'},
  {template: '120: Generic request', description: 'Create a generic change request'},
  {template: '150: Parallel-Approval Request', description: 'Create a traffic change request which requires parallel approvals'},
  {template: '160: Web Filter-Change Request (Blue Coat)', description: 'Create a web-filter change request'},
  {template: '170: Traffic Change Request (IPv6)', description: 'Create a request for IPv6 traffic change in Cisco devices'},
  {template: '180: Traffic Change Request (Multicast)', description: 'Create a request for Multicast traffic change in Cisco devices'},
];

@Component({
  selector: 'app-auswahl-templates',
  templateUrl: './auswahl-templates.component.html',
  styleUrl: './auswahl-templates.component.scss'
})
export class AuswahlTemplatesComponent {
  displayedColumns: string[] = ['template', 'description'];
  templates = ELEMENT_DATA;


  selectedRow: Template | null = null;


  loaddraft(){
    console.log(this.selectedRow)
  }

  selectRow(row: Template) {
    this.selectedRow = row;
  }

}
